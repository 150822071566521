import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const PatreonCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extract the authorization code from the URL query parameters
        const params = new URLSearchParams(location.search);
        const authorizationCode = params.get('code');
        console.log(authorizationCode);
        if (authorizationCode) {
        // Send the code to the backend to exchange for an access token
        axios
            //.get('http://127.0.0.1:5000/callback/ptrn', { code: authorizationCode })
            .get('/callback/patreon', {
                params: { code: authorizationCode }  // Add the query parameters
              })
            .then(response => {
            // Handle successful response and store the access token
            const { access_token } = response.data;

            // Store access token securely (example: in memory or secure HTTP-only cookie)
            localStorage.setItem('access_token', access_token); // Simplified storage
            
            // Redirect to a main page or dashboard after successful login
            navigate('/blink');
            })
            .catch(error => {
            console.error('Error during token exchange:', error);
            // Redirect to error page or show an error message
            navigate('/');
            });
        } else {
        // Handle the case where there is no code in the query params
            navigate('/');
        }
    }, [location, navigate]);

  return <div>Processing login...</div>;
};

export default PatreonCallback;
