import React, { useRef, useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Container } from '@mui/material';
import { InputCameraHandler } from '../components/InputCameraHandler';
import { Canvas, useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei'; // Import Text from drei for text rendering
import { animated, useSpring } from '@react-spring/three';
import AppAppBar from '../components/AppAppBar';

const AbstractEye = ({ innerRadius }) => {
  const eyeRef = useRef();
  const outerSphereRef = useRef();

  // Use spring to animate the outer sphere (pulsating invitation to blink)
  const { scale: outerScale } = useSpring({
    from: { scale: 1.2 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.5 });
        await next({ scale: 1.2 });
      }
    },
    config: { duration: 1000 }, // Adjust speed of the pulse
    loop: true,
  });


  // Add subtle movement to the eye (up and down)
  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    eyeRef.current.position.y = Math.sin(t) * 0.1; // Eye moves slightly up and down
  });

  return (
    <>
      {/* Inner Sphere - Grows with each blink */}
      <mesh ref={eyeRef} position={[0, 0, 0]}>
        <sphereGeometry args={[innerRadius, 32, 32]} />
        <meshStandardMaterial color="hotpink" />
      </mesh>

      {/* Outer Pulsing Sphere - Invitation to Blink */}
      <animated.mesh ref={outerSphereRef} scale={outerScale}>
        <sphereGeometry args={[innerRadius * 1.3, 32, 32]} /> {/* Slightly larger than inner sphere */}
        <meshStandardMaterial color="lightblue" opacity={0.7} transparent />
      </animated.mesh>
    </>
  );
};


// Main App Component
function Exercise3DPage() {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });

  const videoRef =  useRef(null);

  const [showCanvas, setShowCanvas] = useState(false); // State to control canvas visibility
  const [scale, setScale] = useState(0.2);
  const [countdown, setCountdown] = useState(15);
  const [started, setStarted] = useState(false);
  const inputHandlerRef = useRef(null); // Use ref for inputHandler to persist across renders
  const [devType, setDevType] = useState(0);

  useEffect(() => {
    // Start the interval only if inputHandlerRef is initialized
    const intervalId = setInterval(() => {
      if (inputHandlerRef.current) {
        if (inputHandlerRef.current.keys.includes('blink')) {   
          console.log(countdown);    
          setScale(scale + 0.02);
          setCountdown(countdown-1);
          if (!started) setStarted(true);
        }
      }
    }, 500); // Check every 100 milliseconds

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };
  }, [inputHandlerRef, scale]); // Empty dependency array to run once on mount
  
  useEffect(() => {
    if (scale >= 0.5)
      handlePointerDown();
  }, [scale]);

  useEffect(() => {
    const win = window; // Reference the window object

    if (win.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/i)) {
      if (win.navigator.userAgent.match(/(iPhone)/i)) {
        setDevType(1); // iPhone
      }
      if (win.navigator.userAgent.match(/(Android)/i)) {
        setDevType(2); // Android
      }
    }
  }, []); 

  const handleStart = () => {
    console.log("Start");
    if (!inputHandlerRef.current) {
      inputHandlerRef.current = new InputCameraHandler(devType, videoRef); // Initialize InputCameraHandler
    }
    inputHandlerRef.current.start();
    setShowCanvas(true); // Show canvas when start button is clicked
  };

  const handlePointerDown = () => {
    console.log("pointerDown");
    if (inputHandlerRef.current)
      inputHandlerRef.current.stop();
    setShowCanvas(false); // Show canvas when start button is clicked
    inputHandlerRef.current = null;
    setScale(0.2);
    setCountdown(15);
    setStarted(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme />
      {/* Header Navigation */}

      {showCanvas ? ( <></>
      ) : ( <AppAppBar />)
      }

      {/* Main Content */}
      <Container maxWidth="lg">
        <div style={{ position: 'relative', display: 'flex', width: '100%', height: '100vh'}}>
          <video className="input_video" style={{display: "none"}} ref={videoRef}></video>
          {/* Conditionally Render Canvas and Button */}
          {showCanvas ? (
            <Canvas camera={{ position: [0, 0, 4] }} onPointerDown={handlePointerDown}>

            {/* Lighting */}
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} intensity={1} />

            {/* Abstract Eye Component */}
            <AbstractEye innerRadius={scale}/>
              {started ? ( 
                <>
                <Text
                  position={[0, 1.5, 0]} // Position the text above the cube
                  fontSize={0.3} // Set the font size
                  color="#ffffff" // Text color
                  anchorX="center" // Center the text horizontally
                  anchorY="middle" // Center the text vertically
                >
                  {countdown}
                </Text>
                </>
              ) : (               
                <Text
                  position={[0, 1., 0]} // Position the text above the cube
                  fontSize={0.2} // Set the font size
                  color="#ffffff" // Text color
                  anchorX="center" // Center the text horizontally
                  anchorY="middle" // Center the text vertically
                >
                  Blink to increase figure size!
                </Text>
              )
              }

            </Canvas>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStart}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            >
              Start
            </Button>
          )}
        </div>
      </Container>
      </ThemeProvider>
  );
};

export default Exercise3DPage;
