import React, { useRef, useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { InputCameraHandler } from '../components/InputCameraHandler';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei'; // Import Text from drei for text rendering
import { animated, useSpring } from '@react-spring/three';
import { Vector3 } from 'three';
import AppAppBar from '../components/AppAppBar';

const RotatingEyes = ({ rotationIncrement, objectScale }) => {
  const leftEyeRef = useRef();
  const rightEyeRef = useRef();
  const outerSphereRef = useRef();

  // Use spring to animate the outer sphere (pulsating invitation to blink)
  const { scale: outerScale } = useSpring({
    from: { scale: 1.2 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.5 });
        await next({ scale: 1.2 });
      }
    },
    config: { duration: 1000 }, // Adjust speed of the pulse
    loop: true,
  });

  // Use spring to animate the rotation around the center point
  const { rotation } = useSpring({
    rotation: [0, rotationIncrement, 0],
    config: { mass: 1, tension: 280, friction: 60 },
  });
  
  // Central point (between eyes)
  const centerPosition = new Vector3(0, 0, 0);

  // Distance from the center point for each eye (you can adjust this)
  const eyeDistance = 1.5*objectScale;

  return (
    <group>
      {/* Rotating group that contains both eyes */}
      <animated.group rotation={rotation} position={centerPosition}>
        {/* Left Eye */}
        <mesh ref={leftEyeRef} position={[-eyeDistance, 0, 0]}>
        <sphereGeometry args={[0.5*objectScale, 32*objectScale, 32*objectScale]} />
        <meshStandardMaterial color="hotpink" />
        </mesh>

        {/* Right Eye */}
        <mesh ref={rightEyeRef} position={[eyeDistance, 0, 0]}>
          <sphereGeometry args={[0.5*objectScale, 32*objectScale, 32*objectScale]} />
          <meshStandardMaterial color="hotpink" />
        </mesh>
      </animated.group>
    </group>
  );
};


// Main App Component
function Exercise3DPage() {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });

  const videoRef =  useRef(null);

  const [showCanvas, setShowCanvas] = useState(false); // State to control canvas visibility
  const [scale, setScale] = useState(0.0);
  const [countdown, setCountdown] = useState(20);
  const [started, setStarted] = useState(false);
  const inputHandlerRef = useRef(null); // Use ref for inputHandler to persist across renders
  const devType = 0;

  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

  // Set different scales based on orientation
  const objectScale = orientation === 'landscape' ? 1 : 0.65;

  useEffect(() => {
    // Start the interval only if inputHandlerRef is initialized
    const intervalId = setInterval(() => {
      if (inputHandlerRef.current) {
        if (inputHandlerRef.current.keys.includes('horizontal')) {       
          setScale(scale + 0.2);
          setCountdown(countdown-1);
          if (!started) setStarted(true);
        }
      }
    }, 500); // Check every 100 milliseconds

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };
  }, [scale, inputHandlerRef]); // Empty dependency array to run once on mount
  
  useEffect(() => {
    if (scale >= 4.0)
      handlePointerDown();
  }, [scale]);

  const handleStart = () => {
    console.log("Start");
    if (!inputHandlerRef.current) {
      inputHandlerRef.current = new InputCameraHandler(devType, videoRef); // Initialize InputCameraHandler
    }
    inputHandlerRef.current.start();
    setShowCanvas(true); // Show canvas when start button is clicked
  };

  const handlePointerDown = () => {
    console.log("pointerDown");
    if (inputHandlerRef.current)
      inputHandlerRef.current.stop();
    setShowCanvas(false); // Show canvas when start button is clicked
    inputHandlerRef.current = null;
    setScale(0.0);
    setCountdown(20);
    setStarted(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme />
      {/* Header Navigation */}

      {showCanvas ? ( <></>
      ) : ( <AppAppBar />)
      }

      {/* Main Content */}
      <Container maxWidth="lg">
        <div style={{ position: 'relative', display: 'flex', width: '100%', height: '100vh'}}>
          <video className="input_video" style={{display: "none"}} ref={videoRef}></video>
          {/* Conditionally Render Canvas and Button */}
          {showCanvas ? (
            <Canvas camera={{ position: [0, 0, 4] }} onPointerDown={handlePointerDown}>
              {started ? ( 
                <>
                <Text
                  position={[0, 1.5, 0]} // Position the text above the cube
                  fontSize={0.3} // Set the font size
                  color="#ffffff" // Text color
                  anchorX="center" // Center the text horizontally
                  anchorY="middle" // Center the text vertically
                >
                  {countdown}
                </Text>
                </>
              ) : (               
                <Text
                  position={[0, 1.5, 0]} // Position the text above the cube
                  fontSize={0.2*objectScale} // Set the font size
                  color="#ffffff" // Text color
                  anchorX="center" // Center the text horizontally
                  anchorY="middle" // Center the text vertically
                >
                  Move eyes horizontaly to move spheres!
                </Text>
              )
              }
              {/* Basic Lighting */}
              <ambientLight intensity={0.5} />
              <directionalLight position={[5, 5, 5]} intensity={1} />

              {/* Left Eye */}
              <RotatingEyes rotationIncrement={scale} objectScale={objectScale}/>
            </Canvas>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStart}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            >
              Start
            </Button>
          )}
        </div>
      </Container>
      </ThemeProvider>
  );
};

export default Exercise3DPage;
