import { BrowserRouter, Routes, Route } from 'react-router-dom';
/*
import ExercisePage  from './pages/ExercisePage';
import Exercise3DPage from './pages/Exercise3DPage';
*/
import MarketingPage from './pages/MarketingPage';
import Exercise3DBlinkPage from './pages/Exercise3DBlinkPage';
import Exercise3DHorizontalPage from './pages/Exercise3DHorizonatlPage';
import Exercise3DVerticalPage from './pages/Exercise3DVerticalPage';
import PatreonCallback from './components/PatreonCallback';
import PrivateRoute from './components/PrivateRoute';


/*
import Exercise3DVerticalPage from './pages/Exercise3DVerticalPage';
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Checkout from './pages/Checkout'
*/
//import './App.css'

// Main App Component
/*
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Exercise3DBlinkPage/>} />
        <Route path="/signin" element={<SignIn/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/checkout" element={<Checkout/>}/>
        <Route path="/blink" element={<Exercise3DBlinkPage/>} />
        <Route path="/horizontal" element={<Exercise3DHorizontalPage/>} />
        <Route path="/vertical" element={<Exercise3DVerticalPage/>} />
        <Route path="/exer" element={<ExercisePage/>} />
        <Route path="/exer3d" element={<Exercise3DPage/>} />

      </Routes>
    </BrowserRouter>
  );
};
*/

// First deploy
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MarketingPage/>} />
        <Route path="/blink" element={<Exercise3DBlinkPage/>} />
              {/* Protect the Dashboard route using PrivateRoute */}
        <Route
          path="/horizontal"
          element={
            <PrivateRoute>
              <Exercise3DHorizontalPage />
            </PrivateRoute>
          }
        />

        <Route
            path="/vertical"
            element={
              <PrivateRoute>
                <Exercise3DVerticalPage />
              </PrivateRoute>
            }
        />
        <Route path="/callback/ptrn" element={<PatreonCallback/>} />
        <Route path="*" element={<MarketingPage/>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
